













































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Global } from '@/store';

@Component({})
export default class ManagerTondoReports extends Vue {
    
    @Global.State('lang') lang;
    @Global.Mutation('setPageTitle') setPageTitle;
    
    tondoReports = { 
        commissioned_scanned: { title: 'Commissioned & Scanned', text: '', src: require('@/assets/images/reports/commissioned_scanned.png'), color: 'blue-grey lighten-3'},
        commissioned_unscanned: {title: 'Commissioned & Unscanned', text: '', src: require('@/assets/images/reports/commissioned_unscanned.png'), color: 'teal lighten-4'}
    };
    
    mounted(){
        this.setPageTitle('Manager Reports');
    }
}
